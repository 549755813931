import { AppAndTenantConfig } from "./models/tenant/tenant-info";
import config from "./env/environment";
import axios from "axios";

export const getTenant = async (path: string): Promise<AppAndTenantConfig> => {
	let tenant = getPathVariable(path, 1);
	const { standaloneTenant } = config;
	if (!standaloneTenant) {
		const chooseTenantPath = "choose-tenant";
		if (tenant == chooseTenantPath) {
			if (new RegExp(`^/${chooseTenantPath}.+`).test(path)) {
				redirectToPathname(`^/${chooseTenantPath}`);
			}
			return {};
		}
		if (tenant === '') {
			redirectToPathname("/choose-tenant");
		}
	}
	if (!!standaloneTenant) {
		tenant = standaloneTenant;
	}
	const client = await isTenantValid(tenant);
	if (!client.valid) {
		window.location.pathname = "/choose-tenant";
	}
	const chooseAppPattern = `/${tenant}/choose-app`;
	if (path.startsWith(chooseAppPattern)) {
		if (new RegExp(`^${chooseAppPattern}.+`).test(path)) {
			redirectToPathname(chooseAppPattern);
		}
		return { tenant, valid: true };
	}
	let application = getPathVariable(path, 2);
	if (application === '') {
		window.location.pathname = `/${tenant}/choose-app`;
	}

	const validateApp: ValidateAppDto = await validateAppForClient(tenant, application);

	if (validateApp == undefined || validateApp == null || !validateApp.valid) {
		redirectToPathname(chooseAppPattern);
	}
	const { app } = validateApp;
	return { app: app.name, tenant: client.suiteClient.tenantId, client: app.keycloakClientId, valid: true, zabudApp: app };
};

const getPathVariable = (path: string, index: number): string => {
	return path.split("/")[index] || '';
}

const isTenantValid = async (tenant: string): Promise<ValidateClientDto> => {
	try {
		const uri = `${config.gatewayHost}api/access/suite/clients/${tenant}/validate`;
		const response = await axios.get<ValidateClientDto>(uri, {
			headers: {
				'X-TenantID': 'public',
				'Content-Type': 'application/json'
			}
		});
		return response.data;
	} catch (error) {
		return { valid: false, suiteClient: null }
	}
}


const validateAppForClient = async (tenant: string, app: string): Promise<ValidateAppDto> => {
	try {
		const uri = `${config.gatewayHost}api/access/suite/client/apps/${app}/validate`;
		const response = await axios.get<ValidateAppDto>(uri, {
			headers: {
				'X-TenantID': tenant,
				'Content-Type': 'application/json'
			}
		});
		return response.data;
	} catch (error) {
		return null;
	}
}


const redirectToPathname = (pathname: string) => {
	if (!pathname.startsWith("/")) {
		pathname = `/${pathname}`;
	}
	window.location.pathname = pathname;
}