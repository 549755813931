import { doLogin, doInit, REGEX, keycloakInitOptions, buidldKeycloakConfig } from "./auth";
import buildApplications from "./applications";
import { getTenant } from "./tenant-config";
import { AppAndTenantConfig } from "./models/tenant/tenant-info";
import { BehaviorSubject } from "rxjs";
import { BreadCrumb } from "./models/bread-crumb/bread-crumb";
import { tryDownloadFile } from "./file-download";
import config from "./env/environment";
import { validateZaeLock } from "./zae/zae-lock-validator";

async function initSingleSpa() {
  const path = window.location.pathname;
  await tryDownloadFile()
  const appAndTenantInfo = await getTenant(path);
  const exclude = REGEX(appAndTenantInfo.app?.toLowerCase(), appAndTenantInfo.tenant).some((r) => new RegExp(r, "gm").test(path));
  const kcConfig = buidldKeycloakConfig(appAndTenantInfo);
  const auth = await doInit(kcConfig, keycloakInitOptions);
  if (!auth.authenticated && !exclude) {
    await doLogin(auth);
  }

  await validateZaeLock(appAndTenantInfo, auth);
  redirect(appAndTenantInfo, path);

  const custom = {
    auth: auth,
    excludeAuthentication: exclude,
    appAndTenantInfo,
    app: appAndTenantInfo.app,
    breadCrumbs: new BehaviorSubject<BreadCrumb[]>([]),
    configurations: {
      gateway: config.gatewayHost,
      zabudApp: appAndTenantInfo.zabudApp
    },
  };
  buildApplications(custom);
}

initSingleSpa();

function redirect(appAndtenant: AppAndTenantConfig, path: string) {
  if (new RegExp(`^\/${appAndtenant.tenant}\/${appAndtenant.app}$`).test(path)) {
    window.location.pathname = `/${appAndtenant.tenant}\/${appAndtenant.app}/home`;
  }
}
