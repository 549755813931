import { AppAndTenantConfig } from "../models/tenant/tenant-info";
import Keycloak from "keycloak-js";
import config from "./../env/environment";
import axios from "axios";
import * as CryptoJS from 'crypto-js';


export const validateZaeLock = async (appAndTenantInfo: AppAndTenantConfig, auth: Keycloak) => {

    if (!appAndTenantInfo.app || appAndTenantInfo.app !== 'TRONOS') {
        return
    }

    const tokenParsed = auth.tokenParsed;
    const documentNumber = tokenParsed?.document_number;
    const documenType = tokenParsed?.document_type;
    const rolesUSer: string[] = auth?.tokenParsed?.realm_access?.roles || [];
    const rolesToValidate = ['STUDENT', 'TEACHER']


    if (!documentNumber) {
        return
    }

    const validateForRole = rolesToValidate.some((rol: string) => {
        return rolesUSer.includes(rol);
    });

    if (!validateForRole) {
        return;
    }

    try {
        const uri = `${config.zaeBack}zae/v1/pending/evaluation/${documentNumber}`;
        const response = await axios.get<ZaeValidationResponse>(uri, {
            headers: {
                'Authorization': 'Basic UDEwMTAxMDEyOlAxMDEwMTAxMg==',
                'Content-Type': 'application/json'
            }
        });
        const userData = {
            username: documentNumber,
            password: documentNumber
        }

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userData), config.zaeSecret).toString()

        const validationResponse = response.data;
        if (!validationResponse.isValid) {
            window.location.href = `${config.zaeFront}?payload=${ciphertext}`;
        }
        return response.data;
    } catch (error) {
        return null
    }

}

