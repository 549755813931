import axios from 'axios';
import config from "./env/environment";
export const tryDownloadFile = async () => {
    const pathname = window.location.pathname;
    if (pathname.startsWith('/file/download')) {
        const params = new URLSearchParams(window.location.search);
        const s3filePath = params.get('url');
        const urlDownload = `${config.gatewayHost}api/files/files-manager?url=${s3filePath}`;
        if (notEmpty(s3filePath)) {
            const response = await axios.get(urlDownload, {
                responseType: 'blob'
            });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = response.headers['x-file-name'];
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        }
    }
}
function notEmpty(value: string) {
    return value != '' && value != undefined && value != null;
}